import nookies from 'nookies';
import { NextPageContext } from 'next';

const CHECKOUT_ID = 'CHECKOUT_ID';
const BOX_CHECKOUT_ID = 'BOX_CHECKOUT_ID';
const checkoutId = nookies.get(CHECKOUT_ID).CHECKOUT_ID;
const checkoutIdBox = nookies.get(BOX_CHECKOUT_ID).BOX_CHECKOUT_ID;
export const WISH_LIST_LOCAL: string = 'wishlist';
export const WISH_LIST_LOCAL_BOX: string = 'wishlist_box';
export const USER_ID: string = 'USER_ID';
export const SHIPPING_ADDRESS: string = checkoutId + 'shippingAddress';

export const COLORS = {
  dark: '#222C30',
  green: '#11B69C',
  blue: '#17719B',
  grey: '#556166',
  lightBlue: '#F3F8FA',
  lightYellow: '#FEFCED',
  lightGreen: '#ECFCF7',
  darkGreen: '#14949C',
  lightGrey: '#9EACB2',
  lightGrey2: '#9EACB3',
  lightGrey3: '#E9EAEA',
  lightgrey4: '#C2C2C2',
  white: '#FFFFFF',
  status_red: '#EF4A56',
  status_green: '#22B573',
  brightPurlple: '#9447A8',
  lightPurlple: '#FBF5FC',
  lightestBlue: '#F3F8FA',
  brightOrange: '#FC9132',
  darkgrey: '#666666',
  green2: '#1BDFB0',
  got: '#4B4B4B',
  lightGreen2: '#0FD99D',
  black: '#121212',
  black2: '#131313',
};

export const myndProjectConstant = {
  black: '#111111',
  fade_black: '#262626',
  light_green: '#79CC38',
};

export const CHATBOT_COLORS = {
  primary: '#17719b',
  txtmsg: '#EEEEEE',
};

export const ORDER_STATUS: any = {
  'ALL ORDERS': 'ALL',
  ALL: 'ALL',
  CANCELLED: 'CANCELLED',
};

export const blurDataURL =
  'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHcXZIPJF//xAAbEAACAQUAAAAAAAAAAAAAAAAAAQIDEBESIf/aAAgBAQABBQIjPZj6qeLf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAARAAESEicf/aAAgBAQAGPwKVRHXqcL//xAAaEAEBAQADAQAAAAAAAAAAAAABEQAQMVFh/9oACAEBAAE/IcSUjuODU9NZqJ+ud//aAAwDAQACAAMAAAAQfw//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxBn/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/EDV//8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITGRscH/2gAIAQEAAT8QCUKnYoPPGJsNeqQ2/my9sgA6NF9PcWz/2Q==';

export const CLEVERTAP_EVENTS = {
  remove_from_cart: 'Removed from Cart',
  remove_from_box: 'Removed from Box',
  add_to_box: 'Added to Box',
  added_to_cart: 'Added to Cart',
  collection_viewed: 'Collection Viewed',
  product_viewed: 'Product Viewed',
  product_not_found: 'Product Not Found',
  searched_product: 'Searched-Product',
  customer_logged_out: 'Customer Logged Out',
  customer_logged_in: 'Customer Logged In',
  got_error: 'Encountered_Error_In_Mobile_Platform',
  box_created: 'Box Created',
  proceeded_to_checkout: 'Proceeded to Checkout',
  order_created: 'Order Created',
  charged: 'Charged',
  customer_sign_up: 'Customer Signup',
  proceeded_to_ship: 'Proceeded to Shipment',
  proceeded_to_payment: 'Proceeded to Payment',
  payment_method_selected: 'Payment Method Selected',
  blogs_viewed: 'Blogs Viewed',
  Wishlist: 'Added to Wishlist',
  address_selected: 'Address Selected',
};

export const MOBILE_PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=com.corahealth';
export const MOBILE_APPSTORE_URL = 'https://apps.apple.com/ca/app/cora-health-shop-healthy/id1603066627';
export const WHATSAPP_SUPPORT_URL =
  'https://api.whatsapp.com/send?phone=916366100530&text=I+want+to+speak+to+someone+before+ordering';
export const WEBVIEW_USER_AGENT = 'cmch';
export const TRY_NEW_FEATURE = 'ask-email';
export const MONTH_NAMES = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const WISHLIST_REMOVE_SUCCESS: string = 'WISHLIST_REMOVE_SUCCESS';
export const WISHLIST_REMOVE_ERROR: string = 'WISHLIST_REMOVE_ERROR';
export const WISHLIST_REMOVE_LOADING: string = 'WISHLIST_REMOVE_LOADING';
export const WISHLIST_REMOVE_RESET: string = 'WISHLIST_REMOVE_RESET';

export const WISHLIST_ADD_SUCCESS: string = 'WISHLIST_ADD_SUCCESS';
export const WISHLIST_ADD_ERROR: string = 'WISHLIST_ADD_ERROR';
export const WISHLIST_ADD_LOADING: string = 'WISHLIST_ADD_LOADING';
export const WISHLIST_ADD_RESET: string = 'WISHLIST_ADD_RESET';

export const REVIEW_ADD_SUCCESS: string = 'REVIEW_ADD_SUCCESS';
export const REVIEW_ADD_ERROR: string = 'REVIEW_ADD_ERROR';
export const REVIEW_ADD_LOADING: string = 'REVIEW_ADD_LOADING';
export const REVIEW_ADD_RESET: string = 'REVIEW_ADD_RESET';

export const COUPON_GET_SUCCESS: string = 'COUPON_GET_SUCCESS';
export const COUPON_GET_ERROR: string = 'COUPON_GET_ERROR';
export const COUPON_GET_LOADING: string = 'COUPON_GET_LOADING';
export const COUPON_GET_RESET: string = 'COUPON_GET_RESET';

export const COUPON_ADD_SUCCESS: string = 'COUPON_ADD_SUCCESS';
export const COUPON_ADD_ERROR: string = 'COUPON_ADD_ERROR';
export const COUPON_ADD_LOADING: string = 'COUPON_ADD_LOADING';
export const COUPON_ADD_RESET: string = 'COUPON_ADD_RESET';

export const COUPON_REMOVE_SUCCESS: string = 'COUPON_REMOVE_SUCCESS';
export const COUPON_REMOVE_ERROR: string = 'COUPON_REMOVE_ERROR';
export const COUPON_REMOVE_LOADING: string = 'COUPON_REMOVE_LOADING';
export const COUPON_REMOVE_RESET: string = 'COUPON_REMOVE_RESET';

export const ADDRESS_UPDATE_CHECKOUT_SUCCESS: string = 'ADDRESS_UPDATE_CHECKOUT_SUCCESS';
export const ADDRESS_UPDATE_CHECKOUT_ERROR: string = 'ADDRESS_UPDATE_CHECKOUT_ERROR';
export const ADDRESS_UPDATE_CHECKOUT_LOADING: string = 'ADDRESS_UPDATE_CHECKOUT_LOADING';
export const ADDRESS_UPDATE_CHECKOUT_RESET: string = 'ADDRESS_UPDATE_CHECKOUT_RESET';

export const CREATE_RAZOR_ORDER_SUCCESS: string = 'CREATE_RAZOR_ORDER_SUCCESS';
export const CREATE_RAZOR_ORDER_ERROR: string = 'CREATE_RAZOR_ORDER_ERROR';
export const CREATE_RAZOR_ORDER_LOADING: string = 'CREATE_RAZOR_ORDER_LOADING';
export const CREATE_RAZOR_ORDER_RESET: string = 'CREATE_RAZOR_ORDER_RESET';

export const VERIFY_SIGNATURE_SUCCESS: string = 'VERIFY_SIGNATURE_SUCCESS';
export const VERIFY_SIGNATURE_ERROR: string = 'VERIFY_SIGNATURE_ERROR';
export const VERIFY_SIGNATURE_LOADING: string = 'VERIFY_SIGNATURE_LOADING';
export const VERIFY_SIGNATURE_RESET: string = 'VERIFY_SIGNATURE_RESET';

export const CREATE_SHOPIFY_ORDER_SUCCESS: string = 'CREATE_SHOPIFY_ORDER_SUCCESS';
export const CREATE_SHOPIFY_ORDER_ERROR: string = 'CREATE_SHOPIFY_ORDER_ERROR';
export const CREATE_SHOPIFY_ORDER_LOADING: string = 'CREATE_SHOPIFY_ORDER_LOADING';
export const CREATE_SHOPIFY_ORDER_RESET: string = 'CREATE_SHOPIFY_ORDER_RESET';

export const GET_ORDER_SUCCESS: string = 'GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR: string = 'GET_ORDER_ERROR';
export const GET_ORDER_LOADING: string = 'GET_ORDER_LOADING';
export const GET_ORDER_RESET: string = 'GET_ORDER_RESET';

export const GET_CORA_CASH_SUCCESS: string = 'GET_CORA_CASH_SUCCESS';
export const GET_CORA_CASH_ERROR: string = 'GET_CORA_CASH_ERROR';
export const GET_CORA_CASH_LOADING: string = 'GET_CORA_CASH_LOADING';
export const GET_CORA_CASH_RESET: string = 'GET_CORA_CASH_RESET';

export const APPLY_CORA_CASH_SUCCESS: string = 'APPLY_CORA_CASH_SUCCESS';
export const APPLY_CORA_CASH_ERROR: string = 'APPLY_CORA_CASH_ERROR';
export const APPLY_CORA_CASH_LOADING: string = 'APPLY_CORA_CASH_LOADING';
export const APPLY_CORA_CASH_RESET: string = 'APPLY_CORA_CASH_RESET';

export const PAYMENT_METHOD_ADD_SUCCESS: string = 'PAYMENT_METHOD_ADD_SUCCESS';
export const PAYMENT_METHOD_ADD_ERROR: string = 'PAYMENT_METHOD_ADD_ERROR';
export const PAYMENT_METHOD_ADD_LOADING: string = 'PAYMENT_METHOD_ADD_LOADING';
export const PAYMENT_METHOD_ADD_RESET: string = 'PAYMENT_METHOD_ADD_RESET';

export const GET_BLOGS_SUCCESS: string = 'GET_BLOGS_SUCCESS';
export const GET_BLOGS_ERROR: string = 'GET_BLOGS_ERROR';
export const GET_BLOGS_LOADING: string = 'GET_BLOGS_LOADING';
export const GET_BLOGS_RESET: string = 'GET_BLOGS_RESET';

export const GET_BLOG_BY_ID_SUCCESS: string = 'GET_BLOG_BY_ID_SUCCESS';
export const GET_BLOG_BY_ID_ERROR: string = 'GET_BLOG_BY_ID_ERROR';
export const GET_BLOG_BY_ID_LOADING: string = 'GET_BLOG_BY_ID_LOADING';
export const GET_BLOG_BY_ID_RESET: string = 'GET_BLOG_BY_ID_RESET';

export const CHATBOT_MESSAGES_SUCCESS: string = 'CHATBOT_MESSAGES_SUCCESS';
export const CHATBOT_MESSAGES_ERROR: string = 'CHATBOT_MESSAGES_ERROR';
export const CHATBOT_MESSAGES_LOADING: string = 'CHATBOT_MESSAGES_LOADING';
export const CHATBOT_MESSAGES_RESET: string = 'CHATBOT_MESSAGES_RESET';

export const PIN_SEARCH_SUCCESS: string = 'PIN_SEARCH_SUCCESS';
export const PIN_SEARCH_ERROR: string = 'PIN_SEARCH_ERROR';
export const PIN_SEARCH_LOADING: string = 'PIN_SEARCH_LOADING';
export const PIN_SEARCH_RESET: string = 'PIN_SEARCH_RESET';

export const DEFAULT_SEARCH_SUCCESS: string = 'DEFAULT_SEARCH_SUCCESS';

export const GET_CORA_CASH_TRANSACTION_SUCCESS: string = 'GET_CORA_CASH_TRANSACTION_SUCCESS';
export const GET_CORA_CASH_TRANSACTION_ERROR: string = 'GET_CORA_CASH_TRANSACTION_ERROR';
export const GET_CORA_CASH_TRANSACTION_LOADING: string = 'GET_CORA_CASH_TRANSACTION_LOADING';
export const GET_CORA_CASH_TRANSACTION_RESET: string = 'GET_CORA_CASH_TRANSACTION_RESET';

import React, { useEffect, useState } from 'react';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import NextNprogress from 'nextjs-progressbar';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@app/styles/theme';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Provider } from 'react-redux';
import { store, persistor } from '../state/index';
import '../styles/globals.css'; // Importing global css file
import Script from 'next/script';
import { ErrorBoundary } from '@sentry/nextjs';
import { Grid, Typography } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';

import createEmotionCache from '@app/utilities/createEmotionCache.utility';
import { COLORS } from '@app/utilities/constants';
import { useRouter } from 'next/router';
import { isMobileWebView } from '@app/utilities/common.utility';
import dynamic from 'next/dynamic';
import { trackVisitor } from '@app/services/common.service';
const WrapperComponent = dynamic(() => import('../components/wrapper/wrapper'));
const Fallback = dynamic(() => import('@app/components/ErrorBoundary/Fallback'));
import Head from 'next/head';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retry: EnvUtility.isProd(),
      // refetchIntervalInBackground: EnvUtility.isProd(),
      refetchOnWindowFocus: false,
    },
  },
});
const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [connected, setConnected] = useState(true);
  const router = useRouter();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/clevertap_sw.js').then(
          function (registration) {
            console.log('Service Worker registration successful with scope: ', registration?.scope);
          },
          function (err) {
            console.log('Service Worker registration failed: ', err);
          }
        );
      });
    }

    try {
      if (typeof window !== 'undefined') {
        if (router?.query?.utm_source) {
          window.sessionStorage.setItem('utm_source', router?.query?.utm_source as string);
          window.sessionStorage.setItem(
            'utm_source_data',
            JSON.stringify({
              url: window.location.href,
              utm_medium: router?.query?.utm_medium,
              publisherId: router?.query?.tagtag_uid,
            })
          );
        } else {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());

          if (params?.utm_source) {
            window.sessionStorage.setItem('utm_source', params?.utm_source);
            window.sessionStorage.setItem(
              'utm_source_data',
              JSON.stringify({
                utm_source_url: window.location.href,
                utm_medium: params?.utm_medium,
                publisherId: params?.tagtag_uid,
              })
            );
          }
        }
        trackVisitor();
      }
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    if (window.navigator.onLine) {
      setConnected(true);
    } else {
      setConnected(false);
    }
    window.addEventListener('online', () => {
      setConnected(true);
    });
    window.addEventListener('offline', () => {
      setConnected(false);
    });
  }, []);

  return (
    <>
      <Head>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@health_cora" />
        <meta
          name="twitter:title"
          content="Cora | India's largest Health Store. Wholesome Foods, Wholesale prices Cora Health"
        />
        <meta
          name="twitter:description"
          content="Trusted by 1Lakh+ Customers for Health Foods and Supplements. Get Premium, Preservative Free, Sugarfree Breakfast Essentials, Peanut Butter, Oats, Roasted and Guilt Free Snacks, Protein Supplements. Free Shipping & COD available. Get 50% Cashback on 5000+ products. Chat with Nutritionists for diet and product advise"
        />
        <meta
          name="twitter:image"
          content="https://pbs.twimg.com/profile_images/1339484078467235841/aXGkEqok_400x400.jpg"
        />
        <link rel="icon" href={'/favicon.ico'} />
      </Head>

      {/* <Script src="/public/clevertap_sw.js"></Script> */}

      <Script
        strategy="lazyOnload"
        id="admitad"
        src="https://www.artfut.com/static/tagtag.min.js?campaign_code=f5d7855672"
      />

      <Script
        strategy="lazyOnload"
        id="admitad-init"
        dangerouslySetInnerHTML={{
          __html: `
          var cookie_name = 'deduplication_cookie_admitad'; 
          var days_to_store = 2; 
          var deduplication_cookie_value = 'admitad'; 
          var channel_name = 'utm_source'; 
          getSourceParamFromUri = function () { 
          var pattern = channel_name + '=([^&]+)'; 
          var re = new RegExp(pattern); 
          return (re.exec(document.location.search) || [])[1] || ''; 
          }; 
          getSourceCookie = function () { 
          var matches = document.cookie.match(new RegExp( 
          '(?:^|; )' + cookie_name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)' 
          ));
          return matches ? decodeURIComponent(matches[1]) : undefined; }; 
          setSourceCookie = function () { 
            const f =10;
          var param = getSourceParamFromUri(); 

          if (!param) { return; } 
          var period = days_to_store * 60 * 60 * 24 * 1000; 

          var expiresDate = new Date((period) + +new Date); 
          var cookieString = cookie_name + '=' + param + '; path=/; expires=' + expiresDate.toGMTString(); 
          document.cookie = cookieString; 
          document.cookie = cookieString + '; domain=.' + location.host; 
          }; 
          setSourceCookie(); 
          `,
        }}
      />

      {/* {isMobileWebView() && (
        <Script
          id="yellow-bot"
          dangerouslySetInnerHTML={{
            __html:
              'window.ymConfig = {"bot":"x1635504158607","host":"https://cloud.yellowmessenger.com"};(function () {var w=window,ic=w.YellowMessenger;if("function"===typeof ic)ic("reattach_activator"),ic("update",ymConfig);else{var d=document,i=function(){i.c(arguments)};function l(){var e=d.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js";var t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}i.q=[],i.c=function(e){i.q.push(e)},w.YellowMessenger=i,w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}})();',
          }}
        />
      )} */}

      <Script id="gana-init-0" strategy="lazyOnload" src={`https://www.googletagmanager.com/gtm.js?id=GTM-52J6KBP`} />
      <Script
        id="gana-init"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-52J6KBP');
          `,
        }}
      />

      {/* <!-- Global site tag (gtag.js) - Google Ads --> */}
      <Script id="gads-init-1" strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=AW-489246573`} />
      <Script
        id="gads-init"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'AW-489246573');
          `,
        }}
      />

      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <Script
        id="gana-init-1"
        strategy="lazyOnload"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-V8KG9R659B"
      ></Script>
      <Script
        strategy="lazyOnload"
        id="gana-init"
        dangerouslySetInnerHTML={{
          __html: `
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());

           gtag('config', 'G-V8KG9R659B');
          `,
        }}
      ></Script>

      {/* <!-- Meta Pixel Code --> */}
      <Script
        id="pixel-init"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
             !function(f,b,e,v,n,t,s)
             {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
             n.callMethod.apply(n,arguments):n.queue.push(arguments)};
             if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
             n.queue=[];t=b.createElement(e);t.async=!0;
             t.src=v;s=b.getElementsByTagName(e)[0];
             s.parentNode.insertBefore(t,s)}(window, document,'script',
             'https://connect.facebook.net/en_US/fbevents.js');
             fbq('init', '805324183774421');
             fbq('track', 'PageView');
          `,
        }}
      />

      {/* <!-- Meta Pixel Code --> */}
      <Script
        id="pixel-init-2"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
           n.queue=[];t=b.createElement(e);t.async=!0;
           t.src=v;s=b.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window, document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '2695089067394729');
           fbq('track', 'PageView');
          `,
        }}
      />

      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <Script id="gtag-2" strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=UA-169635448-2`} />
      <Script
        id="gads-init-2"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
          
           gtag('config', 'UA-169635448-2');
          `,
        }}
      />

      <Script
        id="clevertap-init"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
          var clevertap = {event:[], profile:[], account:[], onUserLogin:[], notifications:[], privacy:[]};
          clevertap.account.push({"id": "W65-4KR-Z56Z"});
          clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
          clevertap.privacy.push({useIP: false}); //set the flag to true, if the user agrees to share their IP data
           (function () {
                   var wzrk = document.createElement('script');
                   wzrk.type = 'text/javascript';
                   wzrk.async = true;
                   wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
                   var s = document.getElementsByTagName('script')[0];
                   s.parentNode.insertBefore(wzrk, s);
            })();          
          `,
        }}
      />

      <Script
        strategy="lazyOnload"
        id="adjust-init"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: ` 
          !function(t,a,e,r,s,n,l,d,o){t.Adjust=t.Adjust||{},t.Adjust_q=t.Adjust_q||[];for(var c=0;c<n.length;c++)l(t.Adjust,t.Adjust_q,n[c]);d=a.createElement("script"),o=a.getElementsByTagName("script")[0],d.async=!0,d.src="https://cdn.adjust.com/adjust-latest.min.js",d.onload=function(){for(var a=0;a<t.Adjust_q.length;a++)t.Adjust[t.Adjust_q[a][0]].apply(t.Adjust,t.Adjust_q[a][1]);t.Adjust_q=[]},o.parentNode.insertBefore(d,o)}(window,document,0,0,0,["initSdk","trackEvent","addGlobalCallbackParameters","addGlobalPartnerParameters","removeGlobalCallbackParameter","removeGlobalPartnerParameter","clearGlobalCallbackParameters","clearGlobalPartnerParameters","switchToOfflineMode","switchBackToOnlineMode","stop","restart","gdprForgetMe","disableThirdPartySharing","initSmartBanner"],function(t,a,e){t[e]=function(){a.push([e,arguments])}});

          Adjust.initSdk({
            appToken: 'oj188iq2zjls', 
            environment: 'production', 
          });
          `,
        }}
      />

      <Script
        strategy="lazyOnload"
        id="tabola-init"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: ` 
          window._tfa = window._tfa || [];
          window._tfa.push({notify: 'event', name: 'page_view', id: 1473401});
          !function (t, f, a, x) {
          if (!document.getElementById(x)) {
          t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
          }
          }(document.createElement('script'),
          document.getElementsByTagName('script')[0],
          '//cdn.taboola.com/libtrc/unip/1473401/tfa.js',
          'tb_tfa_script');`,
        }}
      />

      <QueryClientProvider client={queryClient}>
        <CacheProvider value={emotionCache}>
          <DefaultSeo
            defaultTitle={"Cora | India's largest Health Store. Wholesome Foods, Wholesale prices Cora Health"}
          />
          <CssBaseline />
          {!connected && !isMobileWebView() && (
            <Grid container sx={{ justifyContent: 'center', position: 'sticky', top: '0px', zIndex: 10000 }}>
              <Grid
                item
                sx={{
                  backgroundColor: COLORS.status_red,
                  zIndex: 10000,
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => router.reload()}
              >
                <Typography sx={{ color: COLORS.white, fontSize: { sm: '14px', xs: '12px' } }}>
                  Please check your network connection and try again.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <WrapperComponent />
                <ErrorBoundary
                  fallback={({ error, componentStack, resetError }) => <Fallback resetError={resetError} />}
                >
                  <Component {...pageProps} />
                </ErrorBoundary>
              </ThemeProvider>
            </PersistGate>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
          <NextNprogress
            color="#64943E"
            startPosition={0.3}
            stopDelayMs={200}
            height={3}
            options={{ showSpinner: false }}
          />
        </CacheProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;

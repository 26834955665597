// import { FETCH_ORDERS } from "../types";
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { orderAction } from '../action/orderAction';
export interface OrderState {
  readonly orders: [];
  readonly isFeaching: boolean;
  readonly nextPage: number | null;
  readonly prevPage: number | null;
}
export const initialState: OrderState = {
  orders: [],
  isFeaching: true,
  nextPage: null,
  prevPage: null,
};

export default createReducer(initialState, (builder) =>
  builder.addCase(orderAction, (state, action: PayloadAction<{}>) => {
    return {
      ...state,
      orders: action?.payload?.orders || [],
      nextPage: action?.payload?.nextPage || null,
      prevPage: action?.payload?.prevPage || null,
      isFeaching: false,
    };
  })
);

import { GET_BLOGS_SUCCESS, GET_BLOGS_ERROR, GET_BLOGS_LOADING, GET_BLOGS_RESET } from '../types';

export const defaultState = {
  payload: null,
  success: false,
  error: false,
  loading: false,
};

const index = (state = defaultState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        payload: action.payload,
      };
    case GET_BLOGS_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        payload: action.payload,
      };
    case GET_BLOGS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
        success: false,
        payload: null,
      };
    case GET_BLOGS_RESET:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        payload: null,
      };
    default:
      return state;
  }
};

export default index;

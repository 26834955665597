import { initializeApp, getApps, getApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth, Auth } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { fetchAndActivate, getRemoteConfig, RemoteConfig } from 'firebase/remote-config';
import { isMobileWebView } from '@app/utilities/common.utility';
import { getValue } from 'firebase/remote-config';

const isClient = !!(typeof window !== 'undefined');

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
};
let remoteConfigInstance: RemoteConfig | null = null;
if (typeof window !== 'undefined') {
  const firebaseApp = getApps().length === 0 ? initializeApp(config) : getApp();
  remoteConfigInstance = getRemoteConfig(firebaseApp);
  remoteConfigInstance.settings.minimumFetchIntervalMillis = 3600000;
  remoteConfigInstance.defaultConfig = {
    miscellaneous: JSON.stringify({ searchProductFromMyServer: true }),
    quizAnimation: JSON.stringify({ quizAnimationType: 'SLIDE-L' }),
  };
  fetchAndActivate(remoteConfigInstance)
    .then(() => {
      if (remoteConfigInstance) {
        const value = getValue(remoteConfigInstance, 'quizAnimation')?.asString();

        if (value) {
          const json = JSON.parse(value);
          localStorage.setItem('QUIZ_ANIMATION_TYPE', json?.quizAnimationType);
        }
      }
    })
    .catch((err) => {
      console.log('remote config, fetch error');
    });

  if (!isMobileWebView()) {
    initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_FIREBASE_APP_CHECK_SITE_KEY!),
      isTokenAutoRefreshEnabled: true,
    });
  }
}

export const auth = (isClient ? getAuth() : null) as Auth;
export const firestoreDB = (isClient ? getFirestore() : null) as Firestore;
export const storage = (isClient ? getStorage() : null) as FirebaseStorage;
export function getRemoteConfigInstance() {
  return remoteConfigInstance;
}

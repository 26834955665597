import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { addUser, userLogout, isMobileWebView } from './action';

export interface UserState {
  readonly name: string;
  readonly email: string;
  readonly uid: string;
  readonly phoneNumber: string;
  readonly isMobileWebView?: boolean;
}

export const initialState: UserState = {
  name: '',
  email: '',
  uid: '',
  phoneNumber: '',
  isMobileWebView: false,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addUser, (state, action: PayloadAction<{}>) => {
      console.log(action, 'action');
      return {
        ...state,
        name: action?.payload?.userData?.name,
        email: action?.payload?.userData?.email,
        uid: action?.payload?.userData?.uid,
        phoneNumber: action?.payload?.userData?.phoneNumber,
      };
    })
    .addCase(userLogout, (state) => {
      return { ...initialState };
    })
    .addCase(isMobileWebView, (state, action) => ({ ...state, isMobileWebView: action.payload.isMobileWebView }))
);

import { createStore, applyMiddleware, combineReducers, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { index } from './reducers';
import { EnvUtility } from '../utilities/env.utility';
import { AnyAction } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'userDetails'],
};

let middleware = [];
if (EnvUtility.isProd()) {
  middleware = [thunk];
} else {
  middleware = [thunk, logger];
}

const store = createStore(
  persistReducer(persistConfig, combineReducers(index)),
  composeWithDevTools(applyMiddleware(...middleware))
);

let persistor = persistStore(store);

export { store, persistor };

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkAppDispatch = ThunkDispatch<AppState, any, AnyAction>;
export const getUserDetailFromReduxStore = (): AppState => store?.getState()?.userDetails;

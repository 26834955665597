// import { FETCH_ORDERS } from "../types";
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getUser, clearUser, updateCustomer, updateCoraCash, updateMyndReferralCode } from '../action/userAction';
import { ICustomerDetails } from '@app/services/users.service';
export interface UserDetailState {
  name: string | null;
  email: string | null;
  uid: string | undefined;
  phoneNumber: string | null;
  cora_cash: { coraCashAmt?: string };
  dob: string | number | undefined;
  gender: string;
  my_address: [];
  coupons: [];
  customer?: ICustomerDetails;
  myndReferralCode?: string;
  myndQuizUrl?: string;
}

export const initialState: UserDetailState = {
  name: '',
  email: '',
  uid: '',
  phoneNumber: '',
  cora_cash: {},
  dob: '',
  gender: '',
  my_address: [],
  coupons: [],
  customer: {},
  myndReferralCode: '',
  myndQuizUrl: '',
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(getUser, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(clearUser, (state, action) => {
      return {
        ...initialState,
      };
    })
    .addCase(updateCustomer, (state, action) => {
      return {
        ...state,
        customer: action.payload || {},
      };
    })
    .addCase(updateCoraCash, (state, action) => {
      return {
        ...state,
        cora_cash: { coraCashAmt: action.payload.coraCashAmt } || {},
      };
    })
    .addCase(updateMyndReferralCode, (state, action) => {
      return {
        ...state,
        myndReferralCode: action.payload || '',
      };
    })
);

import {
  PAYMENT_METHOD_ADD_SUCCESS,
  PAYMENT_METHOD_ADD_ERROR,
  PAYMENT_METHOD_ADD_LOADING,
  PAYMENT_METHOD_ADD_RESET,
} from '../types';

export const defaultState = {
  payload: null,
  success: false,
  error: false,
  loading: false,
};

const index = (state = defaultState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case PAYMENT_METHOD_ADD_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        payload: action.payload,
      };
    case PAYMENT_METHOD_ADD_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        payload: action.payload,
      };
    case PAYMENT_METHOD_ADD_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
        success: false,
        payload: null,
      };
    case PAYMENT_METHOD_ADD_RESET:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        payload: null,
      };
    default:
      return state;
  }
};

export default index;

import user from './user/reducer';
import wishlistAdd from './reducers/wishlistAdd';
import wishlistRemove from './reducers/wishlistRemove';
import addReview from './reducers/addReviewReducer';
import orderReducer from './reducers/orderReducer';
import userReducer from './reducers/userReducer';
import couponsGet from './reducers/getCouponsReducer';
import couponAdd from './reducers/couponAddReducer';
import couponRemove from './reducers/couponRemoveReducer';
import addressUpdate from './reducers/addressUpdateCheckoutReducer';
import createRazorOrder from './reducers/createRazorOrderReducer';
import verifySignature from './reducers/verifySignatureReducer';
import createShopifyOrder from './reducers/createShopifyOrderReducer';
import getOrder from './reducers/getOrderReducer';
import getCoraCash from './reducers/getCoraCashReducer';
import applyCoraCash from './reducers/applyCoraCashReducer';
import addPayMethodReducer from './reducers/addPayMethodReducer';
import getBlogs from './reducers/getBlogsReducer';
import getBlogById from './reducers/getBlogByIdReducer';
import addChatbotMsgReducer from './reducers/addChatbotMsgReducer';
import searchPin from './reducers/pinSearchReducer';
import defaultSearchData from './reducers/addDefaultSearchReducers';
import coraCashTransactions from './reducers/getCoraCashTransactionsReducer';

export const index = {
  user,
  wishlistAdd,
  wishlistRemove,
  addReview,
  couponAdd,
  couponsGet,
  couponRemove,
  addressUpdate,
  createRazorOrder,
  verifySignature,
  createShopifyOrder,
  getOrder,
  getCoraCash,
  applyCoraCash,
  addPayMethodReducer,
  getBlogs,
  getBlogById,
  chatbotMsgs: addChatbotMsgReducer,
  orders: orderReducer,
  userDetails: userReducer,
  searchPin: searchPin,
  defaultSearchData,
  coraCashTransactions,
};

import {
  CART_QUERY,
  CART_ITEM_COUNT_QUERY,
  BOX_CART_QUERY,
  BOX_CART_ITEM_COUNT_QUERY,
} from '@app/constants/query.constant';
import { EnvUtility } from '@app/utilities/env.utility';
import Router from 'next/router';
import { MONTH_NAMES } from './constants';

export const getCurrentBrowserUrl = () => {
  if (typeof window !== 'undefined' && window.location.href) {
    return window.location.href;
  } else {
    return '';
  }
};

export const tTags = (tags: string[]) => {
  const TAG = 'CORAZ-';
  let res: string[] = [];
  tags?.map((tag) => {
    if (tag?.includes(TAG)) {
      const tTag = tag?.replace?.(TAG, '');
      res.push(tTag);
    }
  });
  return res;
};

export const sanitize = (str: string | undefined) => {
  if (!str) {
    return '';
  }
  return str;
};

export const calDiscount = (compareAmt, amt) => {
  const discount = ((Number(compareAmt) - Number(amt)) / Number(compareAmt)) * 100;
  return discount;
};

type KeyTypes = 'cart_item_count_query' | 'cart_query';

export const getQueryKey = (key: KeyTypes, isBoxProduct: boolean): string => {
  if (key === 'cart_item_count_query') {
    let queryKey = isBoxProduct ? BOX_CART_ITEM_COUNT_QUERY : CART_ITEM_COUNT_QUERY;
    if (EnvUtility.isDev()) {
      queryKey = 'DEV_' + queryKey;
    }
    return queryKey as string;
  }
  if (key === 'cart_query') {
    let queryKey = isBoxProduct ? BOX_CART_QUERY : CART_QUERY;
    if (EnvUtility.isDev()) {
      queryKey = 'DEV_' + queryKey;
    }
    return queryKey as string;
  }
  return '';
};

export const isMobileWebView = () => {
  if (typeof window !== 'undefined' && window?.navigator && window?.navigator?.userAgent == 'cmch') {
    return true;
  }
  return false;
};

export const gotoHomePage = () => {
  if (typeof window !== 'undefined' && window?.location?.hostname === 'thecorabox.com') {
    if (
      window?.location?.pathname === '/box' ||
      window?.location?.pathname === '/box/byob' ||
      window?.location?.search?.includes('box=true')
    ) {
      return '/box';
    } else {
      return '/marketplace';
    }
  }
  return '/';
};
gotoHomePage();

export const validateEmailFormat = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export function getFormattedDate(date?: string) {
  if (!date) date = new Date().toISOString();
  const mydate = new Date(date);
  const monthDay = mydate.getDate();
  const lastDigit = monthDay % 10;
  let postFix = 'th';
  if (lastDigit == 1) postFix = 'st';
  else if (lastDigit == 2) postFix = 'nd';
  else if (lastDigit == 3) postFix = 'rd';

  return MONTH_NAMES[mydate.getMonth()] + ' ' + monthDay + ' ' + mydate.getFullYear();
}

export function scaleTrainingValueToHundred(value: number, maxValue: number) {
  return (100 * value) / maxValue;
}

export function getPredefinedEfficiencyFromANS(ansBalance: number, isNight?: number) {
  switch (ansBalance) {
    case 5:
      return { value: 70, label: 'Inefficient', color: '#FF0404' };
    case 4:
      return { value: 50, label: 'Low', color: '#FF8787' };
    case 2:
      return { value: 70, label: 'Moderate', color: isNight ? '#FCD036' : '#2CCCFF' };
    case 1:
      return { value: 50, label: 'Marginal', color: isNight ? '#3685FC' : '#FF9900' };
  }
  return { value: 90, label: 'Peak', color: '#2CFF41' };
}

export function getColorValueForEnergy(energy: number) {
  if (energy < 40) return { color: '#FFA274', value: energy };
  if (energy < 60) return { color: '#FCD036', value: energy };
  return { color: '#32FF46', value: energy };
}

export function getColorValueForMentalFitness(mentalAbility: number) {
  if (mentalAbility == null) {
    return { color: 'white', value: null };
  }
  const value = mentalAbility;
  let color = '#AFE31B';
  if (value > 5 && value <= 15) {
    color = '#79CC38';
  } else if (value >= -15 && value < -5) {
    color = '#FFB13D';
  } else if (value > 15) {
    color = '#32FF46';
  } else if (value < -15) {
    color = '#FFA274';
  }
  return { color, value };
}

export function getColorValueForStrain(strain: number) {
  const content = {
    color: '#FF5B5B', // high
  };
  if (strain > 7 && strain < 20) {
    content.color = '#FCD036'; // moderate
  } else if (strain <= 7) {
    content.color = '#32FF46'; // low
  }
  return { color: content.color, value: strain };
}

export const PRODUCT_LIST_QUERY = 'PRODUCT_LIST_QUERY';

export const CART_QUERY = 'CART_QUERY';
export const CART_ITEM_COUNT_QUERY = 'CART_ITEM_COUNT_QUERY';

export const PRODUCT_BY_COLLECTION = 'PRODUCT_BY_COLLECTION';

export const MINI_CART_COLLECTION = 'MINI_CART_COLLECTION';

export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOG = 'GET_BLOG';

export const GET_COLLECTIONS = 'GET_COLLECTIONS';

export const GET_PRODUCT_BY_TAG = 'GET_PRODUCT_BY_TAG';

export const COUPONS_DATA = 'COUPONS_DATA';

// box queries

export const BOX_CART_QUERY = 'BOX_CART_QUERY';
export const BOX_CART_ITEM_COUNT_QUERY = 'BOX_CART_ITEM_COUNT_QUERY';

import {
  DEFAULT_SEARCH_SUCCESS,
  // CHATBOT_MESSAGES_ERROR,
  // CHATBOT_MESSAGES_LOADING,
  // CHATBOT_MESSAGES_RESET,
} from '../types';

export const defaultState = {
  payload: {
    products: [],
    collection: [],
    loading: false,
    notfound: false,
  },
  success: false,
  error: false,
  loading: false,
};

const index = (state = defaultState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case DEFAULT_SEARCH_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        payload: action.payload,
      };
    // case CHATBOT_MESSAGES_ERROR:
    //   return {
    //     ...state,
    //     success: false,
    //     error: true,
    //     loading: false,
    //     payload: action.payload,
    //   };
    // case CHATBOT_MESSAGES_LOADING:
    //   return {
    //     ...state,
    //     error: false,
    //     loading: true,
    //     success: false,
    //     payload: null,
    //   };
    // case CHATBOT_MESSAGES_RESET:
    //   return {
    //     ...state,
    //     error: false,
    //     loading: false,
    //     success: false,
    //     payload: null,
    //   };
    default:
      return state;
  }
};

export default index;

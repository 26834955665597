import { auth } from '@app/auth/firebase';
import axios from 'axios';

const AuthAxios = axios.create();

AuthAxios.interceptors.request.use(async (config) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    config.headers!.Authorization = `Bearer ${token}`;
    return config;
  } catch (error) {
    console.log('error in auth axios interceptor', error);
  }
});

AuthAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  /**
   * @param {{message:string}} error
   */
  (error) => {
    console.log('error in axios response inceptor', error);
    return Promise.reject(error);
  }
);

export { AuthAxios };

import { AuthAxios } from '@app/services/interceptor.service';
const NEXT_PUBLIC_API_BASE_URL_V1 = process.env.NEXT_PUBLIC_API_BASE_URL + '/api/v1';
import axios from 'axios';

export namespace CommonApiService {
  export interface OrderTransactionInput {
    user_id?: string;
    checkoutId: string;
    orderDetails: Record<string, unknown>;
    checkoutDetails?: Record<string, unknown>;
  }
  export async function getOrderTransaction(orderId: string, checkoutId: string) {
    const res = await AuthAxios.get(
      `${NEXT_PUBLIC_API_BASE_URL_V1}/order-transaction?orderId=${orderId}&checkoutId=${checkoutId}`
    );

    return res;
  }

  export async function addOrderTransaction(body: OrderTransactionInput) {
    const res = await AuthAxios.post(`${NEXT_PUBLIC_API_BASE_URL_V1}/create/order-transaction`, body);
    return res;
  }

  export async function getSnapmintOrderStatus(orderId: string) {
    const res = await axios.get(`${process.env.NEXT_PUBLIC_SNAPMINT_ORDER_STATUS}?order_id=${orderId}`, {
      headers: { Authorization: `Bearer ${process.env.NEXT_PUBLIC_SNAPMINT_MERCHANT_TOKEN}` },
    });
    return res;
  }
}

export async function getWaysToRedeem() {
  if (!process.env.NEXT_PUBLIC_CORA_CASH_API) return;
  const res = await AuthAxios.get(`${process.env.NEXT_PUBLIC_CORA_CASH_API}/ways-to-redeem`);
  return res;
}

export async function redeemCoraCashPoints(body: {
  email: string;
  phoneNumber: string;
  actionId: string;
  cartAmount: string;
}) {
  const emailAddr = body?.email ? body?.email : '';

  const res = await AuthAxios.post(
    `${process.env.NEXT_PUBLIC_CORA_CASH_API}/deduct/points?actionId=${body?.actionId}&cartAmount=${body?.cartAmount}&phoneNumber=${body?.phoneNumber}&email=` +
      emailAddr
  );
  return res;
}

export async function trackVisitor() {
  try {
    console.log('trackVisitor called');

    if (window.sessionStorage.getItem('utm_source') && !window.sessionStorage.getItem('is_utm_source_tracked')) {
      console.log('trackVisitor not tracked');

      let utmSourceData: any = window.sessionStorage.getItem('utm_source_data');

      if (utmSourceData) {
        try {
          utmSourceData = JSON.parse(utmSourceData);
        } catch (e) {}
      }

      const body = {
        utm_source: window.sessionStorage.getItem('utm_source'),
        utm_source_url: encodeURIComponent(utmSourceData?.utm_source_url),
        utm_medium: utmSourceData?.utm_medium,
      };
      const res = await axios.post(`${process.env.NEXT_PUBLIC_API_BASE_URL}/public/create/pixel-visitor-record`, body);
      createPixelVisitorRecordContender(body);
      console.log('trackVisitor', res);
      window.sessionStorage.setItem('is_utm_source_tracked', 'true');
    }
  } catch (error) {
    console.log('error', error);
  }
}

//spren

export async function getTrendChartData(dateSpan: 'week' | 'month', userToken: String, totalDays?: number) {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/spren/user-trend?date_span=${dateSpan}&totalDays=${totalDays}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
    return res;
  } catch (error) {
    console.log('error', error);
    return {
      error: 'Network request failed, Try again please!',
    };
  }
}

//spren
export async function fetchUserData(ftoken: string, userId: string) {
  try {
    const url = new URL(`firebase/user-profile/${userId}`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data;
  } catch (error: any) {
    console.log('error', error);
    return { status: 400, message: error.message };
  }
}

//spren
export async function fetchUserHRVSchedule(ftoken: string, userId: string) {
  try {
    const url = new URL(`spren/hrv-schedule/${userId}`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data;
  } catch (error: any) {
    console.log('error', error);
    return { status: 400, message: error.message };
  }
}

//spren
export async function setUserHRVSchedule(ftoken: string, userId: string, time: string, week_days: Array<string>) {
  try {
    const url = new URL(`spren/hrv-schedule/${userId}`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.put(
      url,
      { time, week_days },
      {
        headers: { Authorization: `Bearer ${ftoken}` },
      }
    );
    return responseData.data;
  } catch (error: any) {
    console.log('error', error);
    return { status: 400, message: error.message };
  }
}

export async function fetchTodaysHRV(ftoken: string, userId: string) {
  try {
    const url = new URL(`spren/get-today-hrv/${userId}`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data;
  } catch (error: any) {
    return { status: 400, message: error.message };
  }
}

export async function fetchLatestHrvs(ftoken: string, userId: string) {
  try {
    const url = new URL(`spren/get-latest-hrv-v2/${userId}`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data;
  } catch (error: any) {
    return { status: 400, message: error.message };
  }
}

export async function fetchTodaysHrvReading(ftoken: string, userId: string) {
  try {
    const url = new URL(`spren/user-insights/${userId}`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data.result;
  } catch (error: any) {
    return { status: 400, message: error.message };
  }
}

export async function fetchHRVInsights(ftoken: string, userId: string, startDt: string, endDt: string) {
  try {
    const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/spren/user-insights/${userId}?startDt=${encodeURIComponent(
      startDt
    )}&endDt=${encodeURIComponent(endDt)}`;

    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data.result;
  } catch (error: any) {
    return { status: 400, message: error.message };
  }
}

export async function fetchTrainingStatus(ftoken: string, userId: string) {
  try {
    const url = new URL(`spren/training-track/${userId}`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data?.result;
  } catch (error: any) {
    return { status: 400, message: error.message };
  }
}

export async function fetchTrainingExercises(ftoken: string, trainingId: string) {
  try {
    const url = new URL(`spren/exercises/${trainingId}`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data?.result;
  } catch (error: any) {
    return { status: 400, message: error.message };
  }
}

export async function pushPostExercisFeeling(ftoken: string, userId: string, feeling: string) {
  try {
    const dataObject = {
      userId,
      trainingId: localStorage.getItem('training_id'),
      trainingDay: localStorage.getItem('training_day'),
      programId: localStorage.getItem('program_id'),
      postFeeling: feeling,
    };
    const url = new URL(`spren/training-hrv-reading`, process.env.NEXT_PUBLIC_API_BASE_URL).href;
    const responseData = await axios.post(url, dataObject, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData.data?.result;
  } catch (error: any) {
    return { status: 400, message: error.message };
  }
}
// contender

export const createServiceReqContender = async (body: any, uid: string) => {
  try {
    if (!process.env.NEXT_PUBLIC_API_BASE_URL_OTHER) return;
    await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL_OTHER}/api/create/service-req-ticket/${uid}`, {
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
  } catch (error) {}
};

export const createPixelRecordContender = async (body: any) => {
  try {
    if (!process.env.NEXT_PUBLIC_API_BASE_URL_OTHER) return;
    await axios.post(`${process.env.NEXT_PUBLIC_API_BASE_URL_OTHER}/api/create/pixel-tracking-record`, body);
  } catch (error) {}
};

export const createPixelVisitorRecordContender = async (body: any) => {
  try {
    if (!process.env.NEXT_PUBLIC_API_BASE_URL_OTHER) return;
    await axios.post(`${process.env.NEXT_PUBLIC_API_BASE_URL_OTHER}/public/create/pixel-visitor-record`, body);
  } catch (error) {}
};

export const quizResponseContender = async (body: any) => {
  try {
    if (!process.env.NEXT_PUBLIC_API_BASE_URL_OTHER) return;
    await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL_OTHER}/public/quiz-response`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {}
};

export const getShortWeekNameByDay = (weekDay: number) => {
  switch (weekDay) {
    case 1:
      return 'M';
    case 2:
      return 'T';
    case 3:
      return 'W';
    case 4:
      return 'T';
    case 5:
      return 'F';
    case 6:
      return 'S';
    case 0:
      return 'S';
  }
  return '';
};

export async function testingGetAllHrvReading(ftoken: string, userId: string) {
  try {
    const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/spren/testing/all-readings/${userId}`;

    const responseData = await axios.get(url, {
      headers: { Authorization: `Bearer ${ftoken}` },
    });
    return responseData?.data?.result;
  } catch (error: any) {
    console.log(error);
  }
}

export async function testingUpdateHrvReading(ftoken: string, userId: string, dataObject: any) {
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/spren/testing/reading/${userId}`;

  const responseData = await axios.post(url, dataObject, {
    headers: { Authorization: `Bearer ${ftoken}` },
  });

  console.log('responseData', responseData);

  if (responseData?.data?.status !== 200) {
    throw new Error(responseData?.data?.result);
  }
  sendPostReqToContender(`spren/testing/reading/${userId}`, dataObject, ftoken);

  return responseData?.data?.result;
}

export async function testingAddHrvReading(ftoken: string, userId: string, dataObject: any) {
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/spren/testing/add-reading/${userId}`;

  const responseData = await axios.post(url, dataObject, {
    headers: { Authorization: `Bearer ${ftoken}` },
  });
  console.log('responseData', responseData);

  if (responseData?.data?.status !== 200) {
    throw new Error(responseData?.data?.result);
  }

  sendPostReqToContender(`spren/testing/add-reading/${userId}`, dataObject, ftoken);

  return responseData?.data?.result;
}

export async function testingDeleteHrvReading(ftoken: string, userId: string, id: string) {
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/spren/testing/reading/${userId}/${id}`;

  const responseData = await axios.delete(url);
  console.log('responseData', responseData);

  if (responseData?.data?.status !== 200) {
    throw new Error(responseData?.data?.result);
  }
  sendDeleteReqToContender(`spren/testing/reading/${userId}/${id}`);

  return responseData?.data?.result;
}

const sendPostReqToContender = async (url: string, body: any, token: string) => {
  try {
    if (!process.env.NEXT_PUBLIC_API_BASE_URL_OTHER) return;
    await axios.post(`${process.env.NEXT_PUBLIC_API_BASE_URL_OTHER}/${url}`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {}
};

const sendDeleteReqToContender = async (url: string) => {
  try {
    if (!process.env.NEXT_PUBLIC_API_BASE_URL_OTHER) return;
    await axios.delete(`${process.env.NEXT_PUBLIC_API_BASE_URL_OTHER}/${url}`);
  } catch (error) {}
};

import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { COLORS } from '@app/utilities/constants';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: ['Public Sans', 'sans-serif'].join(','), //font-family: 'Besley', serif;
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
